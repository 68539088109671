import * as React from "react"
import { authContext } from "./AuthContext"
import { navigate } from "gatsby"
import { AuthPageWrapper, SignUpWrapper } from "./index.styles"
import { Form, Input, Divider } from "semantic-ui-react"

import { validateSignInForm } from "./Validator"
import ErrorMessage from "./ErrorMessage"
import { KpButton } from "@elements/index"
import theme from "@styles/theme"
import { ButtonTypes } from "@utils/Constant"
import { Auth } from "aws-amplify"
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types"
import {
  AuthFormLabel,
  AuthInputMessage,
  AuthSendCode,
  AuthSignupLink,
  AuthSignupMessage,
  AuthTitle,
  PasswordResetLink,
} from "@styles/Global.forms"
import { FcGoogle } from "react-icons/fc"
import { FaFacebookF } from "react-icons/fa"
import { useState } from "react"

const SignIn = () => {
  const { auth } = React.useContext(authContext)

  return (
    <AuthPageWrapper>
      <SignUpWrapper>
        <AuthTitle>Login</AuthTitle>
        <SignInForm />
        <Divider horizontal style={{ color: "#666" }}>
          Or
        </Divider>
        <div style={{ textAlign: "center", paddingTop: "15px" }}>
          <KpButton
            id="signin-submit"
            onClick={() => {
              Auth.federatedSignIn({
                provider: CognitoHostedUIIdentityProvider.Google,
              })
            }}
            color={theme.brand.colors.darkGrey}
            buttonType={ButtonTypes.Secondary}
            fullWidth="mobile tablet computer"
            textColor={theme.brand.colors.black}
            type="submit"
          >
            <div style={{ width: "100%", paddingLeft: "15px" }}>
              <FcGoogle size={24} style={{ float: "left" }} />
              <span style={{ lineHeight: "24px" }}>Login with Google</span>
            </div>
          </KpButton>
        </div>
        <div style={{ textAlign: "center", paddingTop: "15px" }}>
          <KpButton
            id="signin-submit"
            onClick={() => {
              Auth.federatedSignIn({
                provider: CognitoHostedUIIdentityProvider.Facebook,
              })
            }}
            color={theme.brand.colors.darkGrey}
            buttonType={ButtonTypes.Secondary}
            fullWidth="mobile tablet computer"
            type="submit"
          >
            <div style={{ width: "100%", paddingLeft: "15px" }}>
              <FaFacebookF
                size={24}
                style={{ float: "left", color: "#1877f2" }}
              />{" "}
              <span style={{ lineHeight: "24px" }}>Login with Facebook</span>
            </div>
          </KpButton>
        </div>
        <div style={{ textAlign: "center", paddingTop: "10px" }}>
          <AuthSignupMessage>Don't have an account?</AuthSignupMessage>
          <AuthSignupLink to="/signup">Sign up now!</AuthSignupLink>
        </div>
      </SignUpWrapper>
    </AuthPageWrapper>
  )
}
export default SignIn

const SignInForm = () => {
  const [account, setAccount] = useState({ email: "", password: "" })
  const [error, setError] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [resendCode, setResendCode] = useState(false)
  const [codeSent, setCodeSent] = useState(false)

  const updateAccount = (account: { email: string; password: string }) => {
    setAccount(account)
    setError("")
    setResendCode(false)
    setCodeSent(false)
  }

  const resend = () => {
    Auth.resendSignUp(account.email).then(res => {
      console.log(res)
      setCodeSent(true)
      setError("")
    })
  }

  const handleSignIn = (e: any) => {
    e.preventDefault()
    if (validateSignInForm(account.email, account.password, setError)) {
      setLoading(true)
      Auth.signIn(account.email, account.password)
        .then(res => {
          if (res.challengeName === "NEW_PASSWORD_REQUIRED") {
            navigate("/reset-password/")
          } else {
            setLoading(false)
            console.log(res)
            navigate("/app/listings")
            return
          }
        })
        .catch(exception => {
          if (exception.code == "UserNotConfirmedException") {
            setResendCode(true)
          }
          setError(exception.message)
          setLoading(false)
        })
    }
  }
  return (
    <Form onSubmit={handleSignIn}>
      <Form.Field>
        <AuthFormLabel>EMAIL</AuthFormLabel>
        <Input
          type="email"
          name="email"
          value={account.email}
          id="email"
          placeholder="yourname@email.com"
          onChange={e => updateAccount({ ...account, email: e.target.value })}
        />
      </Form.Field>
      <Form.Field>
        <AuthFormLabel>PASSWORD</AuthFormLabel>
        <AuthInputMessage>
          <PasswordResetLink to="/forgot-password">
            Forgot your password?
          </PasswordResetLink>
        </AuthInputMessage>
        <Input
          type="password"
          name="password"
          value={account.password}
          id="password"
          onChange={e =>
            updateAccount({ ...account, password: e.target.value })
          }
        />
      </Form.Field>
      <Form.Field style={{ textAlign: "center", padding: "10px 0 10px 0" }}>
        <KpButton
          id="signin-submit"
          color={theme.brand.colors.green}
          fullWidth="mobile tablet computer"
          buttonType={ButtonTypes.Primary}
          loading={loading}
          type="submit"
        >
          {loading ? "Loading..." : "Log in"}
        </KpButton>
      </Form.Field>
      {error && <ErrorMessage errorMessage={error} />}
      {resendCode && (
        <div style={{ textAlign: "center", paddingTop: "10px" }}>
          <AuthSignupMessage>
            Don't verify your email address?
          </AuthSignupMessage>
          <AuthSendCode onClick={resend}>Re-send!</AuthSendCode>
        </div>
      )}
      {codeSent && (
        <div style={{ textAlign: "center", paddingTop: "10px" }}>
          <AuthSignupMessage>
            Please check your email to verify your email address.
          </AuthSignupMessage>
        </div>
      )}
    </Form>
  )
}